import * as api from "../api";
import { setWaitingUpload } from "./global";

export const getLogs = (history, page) => async (dispatch) => {
  try {
    await api
      .getLogs(page)
      .then((res) => {
        if (res.status === 200) {
          dispatch({ type: "GET_LOGS", payload: res.data });
        }
      })
      .catch((err) => {
        dispatch({ type: "AUTH_LOGOUT" });
        dispatch({
          type: "SET_ERRORMESSAGE",
          payload: err,
        });
        history.push("/error");
      });
  } catch (error) {
    console.error(error);
  }
};
