const globalReducer = (
  state = { activeTab: "/hem", blogPostCreatorData: "", kpi: [] },
  action
) => {
  switch (action.type) {
    case "SET_ACTIVE_TAB":
      return {
        ...state,
        activeTab: action.payload,
      };
    case "SET_BLOGPOST_CREATOR_DATA":
      return {
        ...state,
        blogPostCreatorData: action.payload,
      };
    case "SET_ROUTING_CREATOR_DATA":
      return {
        ...state,
        routingCreatorData: action.payload,
      };
    case "COUNT_KPI":
      if (state.kpi.find((e) => Object.keys(e)[0] === action.payload.type)) {
        return { ...state };
      } else {
        return {
          ...state,
          kpi: [...state.kpi, { [action.payload.type]: action.payload.data }],
        };
      }
    case "SET_ERRORMESSAGE": {
      return {
        ...state,
        errorMessage: action.payload,
      };
    }
    case "SET_ACTIVE_ROUTEPOST_EDITOR": {
      return {
        ...state,
        activeRoutePostEditor: action.payload,
      };
    }
    case "PING": {
    }
    case "SET_WAITING_UPLOAD": {
      return {
        ...state,
        waitingForUpload: action.payload,
      };
    }

    case "SET_ROUTE_POSTS_FILTER": {
      return {
        ...state,
        routePostsFilter: action.payload,
      };
    }

    case "SET_SUB_ROUTE_POSTS_FILTER": {
      return {
        ...state,
        subRoutePostsFilter: action.payload,
      };
    }
    default:
      return state;
  }
};

export default globalReducer;
