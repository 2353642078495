import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { LinkItem, Icon, Wrapper } from "./styles";
import { setActiveTab } from "../../../actions/global";
import { toggleAdminEditor, ToggleLeftNav } from "../../../actions/navbar";
import RoutePostsCreator from "../../RoutePostsCreator/RoutePostsCreator";

function LeftNavItem({ value, link, editableChildren = false, type }) {
  const [activeNow, setActiveNow] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const activeTab = useSelector((state) => state.globalReducer.activeTab);
  const [loggedInUser, setLoggedInUser] = useState(null);

  const styleActive = {
    "-webkit-transform": "rotate(90deg)",
    "-moz-transform": "rotate(90deg)",
    "-ms-transform": "rotate(90deg)",
    "-o-transform": "rotate(90deg)",
    transform: "rotate(90deg)",
  };

  const styleNotActive = {
    "-webkit-transform": "rotate(0deg)",
    "-moz-transform": "rotate(0deg)",
    "-ms-transform": "rotate(0deg)",
    "-o-transform": "rotate(0deg)",
    transform: "rotate(0deg)",
  };

  useEffect(() => {
    setLoggedInUser(JSON.parse(localStorage.getItem("user")));
  }, [location]);

  useEffect(() => {
    const url = location.pathname;
    if (url === link) {
      dispatch(setActiveTab(link));
    }
  }, []);

  useEffect(() => {
    if (activeTab === link) {
      setActiveNow(true);
    } else {
      setActiveNow(false);
    }
    if (editableChildren && activeNow) {
      dispatch(toggleAdminEditor());
    }
  }, [activeTab]);

  const onClick = () => {
    dispatch(setActiveTab(link));
    dispatch(ToggleLeftNav());
  };

  return (
    <Wrapper>
      <LinkItem
        onClick={onClick}
        to={link}
        style={{
          backgroundColor: activeNow
            ? getComputedStyle(document.documentElement).getPropertyValue(
                "--mainHoverColor"
              )
            : "",
        }}
      >
        {/*loggedInUser && editableChildren && (
          <Icon
            style={activeNow && editableChildren ? styleActive : styleNotActive}
            className="fas fa-caret-right"
          />
        )*/}
        {value}
      </LinkItem>
      {/*<BlogPostCreator disabled={activeTab === link && editableChildren && viewDropdown && !activeNow ? false : true} />*/}
      {loggedInUser && (
        <RoutePostsCreator
          route={{}}
          type={type}
          disabled={activeNow && editableChildren ? false : true}
        />
      )}
    </Wrapper>
  );
}

export default LeftNavItem;
