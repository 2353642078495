import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {
  LinkItem,
  Icon,
  Wrapper,
  DeleteIcon,
  LinkItemWrapper,
  DeleteContainer,
  IconContainer,
  IconContainer2,
  ArrowUp,
  ArrowDown,
  CaretDownIcon,
  CaretRightIcon,
} from "./styles";
import {
  deleteRoute,
  getRoutePosts,
  editRoute,
  getRoutes,
} from "../../../actions/routes";
import { toggleAdminEditor, ToggleLeftNav } from "../../../actions/navbar";
import {
  setActiveRoutePostEditor,
  setActiveTab,
  setRoutingCreatorData,
} from "../../../actions/global";
import RoutePostsCreator from "../../RoutePostsCreator/RoutePostsCreator";
import SubRoutingContainer from "../../SubRoutingContainer/SubRoutingContainer";
import { AllIcons } from "../../../Icons/Icon";

function CustomRoute({
  name,
  id,
  link,
  editableChildren = true,
  route,
  routesLength = 0,
  activeSubRoute,
  setActiveSubRoute,
}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [activeNow, setActiveNow] = useState(false);

  const activeTab = useSelector((state) => state.globalReducer.activeTab);
  const activeRoutePostEditor = useSelector(
    (state) => state.globalReducer.activeRoutePostEditor
  );
  const [styleDisabledUp, setStyleDisabledUp] = useState(null);
  const [styleDisabledDown, setStyleDisabledDown] = useState(null);
  const [loggedInUser, setLoggedInUser] = useState(null);

  useEffect(() => {
    setStyleDisabledUp(
      route.order <= 1
        ? { cursor: "not-allowed", fill: "var(--mainDisabledColor)" }
        : {}
    );

    setStyleDisabledDown(
      route.order >= routesLength
        ? { cursor: "not-allowed", fill: "var(--mainDisabledColor)" }
        : {}
    );
  }, [route, routesLength]);

  useEffect(() => {
    setLoggedInUser(JSON.parse(localStorage.getItem("user")));
  }, [location]);

  useEffect(() => {
    const url = location.pathname;
    if (url === link) {
      dispatch(setActiveTab(link));
    }
  }, []);

  useEffect(() => {
    if (activeTab === link) {
      setActiveNow(true);
    } else {
      setActiveNow(false);
    }
    if (editableChildren && activeNow) {
      dispatch(toggleAdminEditor());
    }
  }, [activeTab]);

  const deleteCustomRoute = () => {
    dispatch(deleteRoute(id, history, loggedInUser));
  };

  const onClick = () => {
    dispatch(setRoutingCreatorData(null));
    dispatch(setActiveRoutePostEditor(link));
    dispatch(setActiveTab(link));
    setActiveSubRoute(null);
    if (!loggedInUser) {
      if (!route.subRoute) {
        dispatch(ToggleLeftNav());
      }
    }
  };

  const changeOrder = (e, val) => {
    e.stopPropagation();
    e.preventDefault();
    const newRoute = {
      ...route,
      order: route.order + val,
    };
    dispatch(
      editRoute(
        id,
        {
          newRoute,
          oldRoute: route,
          isSubroute: false,
          parentRoute: null,
          user: loggedInUser,
        },
        history
      )
    );
    //setTimeout(() => dispatch(getRoutes(history)), [700]); //Very ugly fix how do we fix it?
  };

  return (
    <Wrapper>
      <LinkItem
        style={{
          backgroundColor: activeNow
            ? getComputedStyle(document.documentElement).getPropertyValue(
                "--mainHoverColor"
              )
            : "",
        }}
        onClick={onClick}
        to={`/r/${id}`}
      >
        {loggedInUser && (
          <IconContainer2>
            <ArrowUp
              style={styleDisabledUp}
              onClick={route.order == 1 ? () => {} : (e) => changeOrder(e, -1)}
            />

            <ArrowDown
              style={styleDisabledDown}
              onClick={
                route.order >= routesLength
                  ? () => {}
                  : (e) => changeOrder(e, +1)
              }
            />
          </IconContainer2>
        )}

        {route && route.icon && (
          <IconContainer>{AllIcons[route.icon]}</IconContainer>
        )}
        {route.subRoute && (
          <>
            {!activeNow && link.replace("/r/", "") !== activeSubRoute && (
              <IconContainer2>{<CaretRightIcon />}</IconContainer2>
            )}
            {(activeNow || link.replace("/r/", "") == activeSubRoute) && (
              <IconContainer2>{<CaretDownIcon />}</IconContainer2>
            )}
          </>
        )}
        {name}
        {loggedInUser && (
          <DeleteContainer>
            <DeleteIcon onClick={deleteCustomRoute} />
          </DeleteContainer>
        )}
      </LinkItem>
      {loggedInUser && activeNow && activeRoutePostEditor == link && (
        <RoutePostsCreator route={route} />
      )}
      {(activeNow || link.replace("/r/", "") === activeSubRoute) &&
        route.subRoute && (
          <SubRoutingContainer
            setActiveSubRoute={setActiveSubRoute}
            parentId={id}
          />
        )}
    </Wrapper>
  );
}

export default CustomRoute;
