const routingReducer = (state = [], action) => {
  switch (action.type) {
    case "FETCH_ROUTES": {
      return {
        ...state,
        routes: action?.payload?.data || [],
      };
    }
    case "CREATE_ROUTE": {
      let routes = state.routes;
      if (routes.length !== 0) {
        routes.push(action.payload);
      } else {
        routes = action.payload;
      }
      return {
        ...state,
        routes: Array.isArray(routes) ? [...routes] : [routes],
      };
    }
    case "DELETE_ROUTE": {
      const newRoutes = state.routes.filter(
        (route) => route._id !== action.payload
      );
      return {
        ...state,
        routes: newRoutes,
      };
    }
    case "UPDATE_ROUTE": {
      const routes = state.routes || [];
      const index = routes.findIndex((r) => r._id == action.payload._id);
      if (index != -1) {
        routes[index] = action.payload;
      }
      return {
        ...state,
        routes: routes,
      };
    }
    case "FETCH_ROUTE_POSTS": {
      let routePosts = action?.payload?.data || [];
      let routeCurrentPage = action?.payload?.currentPage;
      let routeNumberOfPages = action?.payload?.numberOfPages;
      return {
        ...state,
        routePosts,
        routeCurrentPage,
        routeNumberOfPages,
      };
    }
    case "POST_ROUTE_DATA": {
      let newRoutePosts = state.routePosts;
      if (newRoutePosts.length !== 0) {
        newRoutePosts.unshift(action.payload);
      } else {
        newRoutePosts = action.payload;
      }
      return {
        ...state,
        newRoutePosts,
      };
    }
    case "DELETE_ROUTE_DATA": {
      const newRoutePostsAfterDeletion = state.routePosts.filter(
        (routePost) => routePost._id != action.payload
      );
      return {
        ...state,
        routePosts: newRoutePostsAfterDeletion,
      };
    }
    case "EDIT_ROUTE_DATA": {
      return {
        ...state,
        routePosts: state.routePosts.map((post) =>
          post._id === action.payload._id ? action.payload : post
        ),
      };
    }
    case "CREATE_SUBROUTE": {
      let routes = state.routes;
      if (routes.length !== 0) {
        routes.push(action.payload);
      } else {
        routes = action.payload;
      }
      return {
        ...state,
        routes: Array.isArray(routes) ? [...routes] : [routes],
      };
    }
    case "FETCH_SUBROUTES_DATA": {
      let subRoutePosts = action?.payload?.data || [];
      let subRouteCurrentPage = action?.payload?.currentPage;
      let subRouteNumberOfPages = action?.payload?.numberOfPages;
      let a = {
        ...state,
        subRoutePosts,
        subRouteCurrentPage,
        subRouteNumberOfPages,
      };

      return a;
    }
    case "FETCH_FILE": {
      const file = action.payload;
      const { _id: fileId } = file;
      const files = state.files || [];
      const index = files.findIndex((e) => e._id == fileId);

      if (index == -1) {
        files.push(file);
      } else {
        //PRopably check if the Object.value is the same if not we should push it (since more images on one post)
        files[index] = file;
      }

      return {
        ...state,
        files,
      };
    }
    case "GET_ROUTEPOST": {
      const routePost = Array.isArray(action?.payload?.data)
        ? action?.payload?.data[0]
        : action?.payload?.data;
      return {
        ...state,
        detailedPost: routePost,
      };
    }
    case "EDITING_FILE": {
      return {
        ...state,
        editingFile: action.payload,
      };
    }
    case "SET_DETAILED_POST": {
      return {
        ...state,
        detailedPost: action.payload,
      };
    }
    case "EMPTY_ROUTEPOSTS": {
      return {
        ...state,
        routePosts: null,
      };
    }
    case "EMPTY_SUB_ROUTEPOSTS": {
      return {
        ...state,
        subRoutePosts: null,
      };
    }
    default:
      return state;
  }
};

export default routingReducer;
