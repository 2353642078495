import React, { useEffect } from "react";
import Loader from "react-loader-spinner";
import { LoadContainer } from "./styles";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

function LoaderSpinner({
  type = "Grid",
  height = 45,
  width = 45,
  timeout = 900000,
  containerHeight = "70vh",
  display = "flex",
  containerwidth = "auto",
  fallbackMessage = "Här vart det tomt!",
  //fallbackMessage = "Kom gärna tillbaka en annan gång för att se om det har uppdaterats något.",
}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const color = getComputedStyle(document.documentElement).getPropertyValue(
    "--mainHoverColor"
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch({ type: "SET_ERRORMESSAGE", payload: fallbackMessage });
      history.push("/error");
    }, timeout);

    return () => clearTimeout(timer);
  }, []);

  return (
    <LoadContainer
      style={{
        height: containerHeight,
        width: containerwidth,
        display: display,
      }}
    >
      <Loader
        type={type}
        color={color}
        height={height}
        width={width}
        timeout={timeout}
      />
    </LoadContainer>
  );
}

export default LoaderSpinner;
