import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import React from "react";
import LeftNavBar from "./components/LeftNavBar/LeftNavBar";
import TopNavBar from "./components/TopNavBar/TopNavBar";
import LoaderSpinner from "./components/Loader/LoaderSpinner";

const Home = React.lazy(() => import("./components/StandardNavItems/Home/Home"));
const OmOss = React.lazy(() => import("./components/StandardNavItems/OmOss/OmOss"));
const KopaValp = React.lazy(() => import("./components/StandardNavItems/KopaValp/KopaValp"));
const Contact = React.lazy(() => import("./components/StandardNavItems/Contact/Contact"));
const Settings = React.lazy(() => import("./components/Settings/Settings"));
const RoutePosts = React.lazy(() =>
  import("./components/RoutePosts/RoutePosts")
);
const SubRoutePosts = React.lazy(() =>
  import("./components/SubRoutePosts/SubRoutePosts")
);
const LoginForm = React.lazy(() => import("./components/LoginForm/LoginForm"));
const DetailedPost = React.lazy(() =>
  import("./components/RoutePosts/DetailedPost/DetailedPost")
);

function App() {
  return (
    <BrowserRouter>
      <TopNavBar />
      <LeftNavBar />
      <Switch>
        <React.Suspense fallback={<LoaderSpinner containerwidth="100vw" />}>
          <Route exact path="/" component={() => <Redirect to="/hem" />} />
          <Route exact path="/hem" component={Home} />
          <Route exact path="/om-oss" component={OmOss} />
          <Route exact path="/om-rasen" component={KopaValp} />
          <Route exact path="/kontakt" component={Contact} />
          <Route exact path="/login-admin" component={LoginForm} />
          <Route exact path="/installningar" component={Settings} />
          <Route exact path="/r/:id" component={RoutePosts} />
          <Route exact path="/r/rp/:id" component={DetailedPost} />
          <Route
            exact
            path="/r/:parentId/sr/:id"
            component={SubRoutePosts}
          />
        </React.Suspense>
      </Switch>
    </BrowserRouter>
  );
}

export default App;
