import styled from "styled-components";

export const NavBar = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 300px;
  width: 300px;
  min-width: 300px;
  background-color: var(--secondaryColor);
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 1;
  overflow: hidden;
  transition: 0.35s;
  box-shadow: 4px 0px 5px rgba(0, 0, 0, 0.6);
  padding: 70px 10px 10px 10px;
  justify-content: space-between;
`;

export const Wrapper = styled.div`
  overflow-y: auto;
  max-height: 85vh;
`;

export const OuterWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: ${(props) => (props.activeNav ? "100vw" : "auto")});
  height: 100vh;
  z-index: 1;
`;

export const CloseLeftNav = styled.div`
  width: 100vw;
  height: 100vh;
  display: ${(props) => (!props.activeNav ? "none" : "")};
`;
