import axios from "axios";
import config from "../config/config";
const API = axios.create({ baseURL: config.API_URL });

API.interceptors.request.use((req) => {
  if (localStorage.getItem("user")) {
    req.headers.Authorization = `Bearer ${
      JSON.parse(localStorage.getItem("user")).token
    }`;
  }
  return req;
});

//AuthAdmin
export const signIn = (formData) => API.post("/userAdmin/signin", formData);
export const getAdmins = (page) => API.post(`/userAdmin/admins/?page=${page}`);
export const getNonApprovedAdmins = (page, body = null) =>
  API.post(`/userAdmin/admins/?page=${page}`, { query: body });
export const approveDisapproveAdmin = (body) =>
  API.post(`/userAdmin/admins/approve`, body);
export const createAdmin = (body) =>
  API.post(`/userAdmin/admins/admin/create`, body);

//Routes
export const createRoute = (newRoute) => API.post(`/r`, newRoute);
export const getRoutes = () => API.get("/r");
export const deleteRoute = (id, user) =>
  API.delete(`/r/${id}`, { data: { user: user } });
export const editRoute = (id, newRouteData) =>
  API.patch(`/r/${id}`, newRouteData);

//Sub routes
export const fetchSubRouteData = (parentId, childId, id, page, query) =>
  API.post(`/r/${parentId}/sr/${childId}/?page=${page}`, query);
export const createSubRoute = (id, data) => API.post(`/r/${id}/sr/`, data);
export const deleteSubRoute = (parentId, childId) =>
  API.delete(`/r/${parentId}/sr/${childId}`);
export const createSubRouteData = (parentId, childId, data) =>
  API.post(`/r/${parentId}/sr/${childId}`, data);
export const deleteSubRouteData = (parentId, childId, id) => {
  API.post(`/r/${parentId}/sr/${childId}/${id}`);
};
export const editSubRouteData = (parentId, childId, id, data) =>
  API.patch(`/r/${parentId}/sr/${childId}/${id}`, data);
export const fetchFiles = (postId) => API.get(`/f/${postId}`);

//Routedata
export const getRoutePosts = (id, page, query) =>
  API.post(`/r/${id}/?page=${page}`, query);
export const getRoutePost = (id) => API.get(`/r/rp/${id}`);
export const postRouteData = (id, data) => API.post(`/r/post/${id}`, data);
export const deleteRouteData = (routeId, dataId, user) =>
  API.delete(`/r/${routeId}/${dataId}`, { data: { user: user } });
export const editRouteData = (routeId, dataId, newData) =>
  API.patch(`/r/${routeId}/${dataId}`, newData);

//logs
export const getLogs = (page) => API.get(`/l/?page=${page}`);

//integrations
export const getIntegrationsWithConfig = () =>
  API.post(`/integrations/allWithConf`);
export const createNewIntegration = (integration, user) =>
  API.post(`/integrations/createNew`, { integration, user });
export const getDBIntegrations = () => API.post(`/integrations/dbIntegrations`);
export const updateIntegration = (integration, user) =>
  API.post(`/integrations/updateIntegration`, { integration, user });
export const deleteIntegration = (integrationId, user) =>
  API.post(`/integrations/deleteIntegration`, { integrationId, user });

//cron
export const createNewCron = (cron, user) =>
  API.post("/cron/createNew", { cron, user });
export const getDBCrons = () => API.post(`/cron/getDBCrons`);
export const updateCron = (cron, user) =>
  API.post(`/cron/updateCron`, { cron, user });
export const deleteCron = (cronId, user) =>
  API.post(`/cron/deleteCron`, { cronId, user });
//test
export const ping = () => API.get("/connection/ping");
