/**
 * Used in the left navbar to display all the available custom routes.
 */

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import RoutingCreator from "../RoutingCreator/RoutingCreator";
import { Wrapper, AddIcon } from "./styles";
import CustomSubRoute from "./CustomSubRoute/CustomSubRoute";

function SubRoutingContainer({
  activetype,
  editableChildren = false,
  parentId,
  setActiveSubRoute,
}) {
  const dispatch = useDispatch();
  const location = useLocation();
  const routes = useSelector((state) => state.routingReducer.routes);
  const [routesLength, setRoutesLength] = useState(0);

  const addNewRoute = () => {
    //Dispatch / set another active blogpostcreator so you wont see the others in the menu.
    setActiveNow(!activeNow);
  };

  useEffect(() => {
    if (routes && routes.length)
      setRoutesLength(
        routes.filter(
          (route) => route.isSubRoute === true && route.parentRoute == parentId
        ).length
      );
  }, [routes]);

  useEffect(() => {
    //dispatch(getRoutes());
  }, []);

  const [activeNow, setActiveNow] = useState(false);

  useEffect(() => {}, [activeNow]);

  const hideRoutingContainer = () => {
    setActiveNow((state) => !state);
  };

  const [loggedInUser, setLoggedInUser] = useState(null);

  useEffect(() => {
    setLoggedInUser(JSON.parse(localStorage.getItem("user")));
  }, [location]);

  return (
    <Wrapper>
      {routes &&
        routes
          .filter(
            (route) =>
              route.isSubRoute === true && route.parentRoute == parentId
          )
          .map((route) => (
            <CustomSubRoute
              link={`/r/${route.parentRoute}/sr/${route._id}`}
              setActiveSubRoute={setActiveSubRoute}
              name={route.name}
              id={route._id}
              route={route}
              parentId={route.parentRoute}
              routesLength={routesLength}
            />
          ))}

      {loggedInUser && (
        <div
          style={{ display: "flex", flexDirection: "column", width: "100%" }}
        >
          {activeNow && (
            <RoutingCreator
              parentId={parentId}
              hideRoutingContainer={hideRoutingContainer}
              type={"customRouting"}
              handlingSubRoute={true}
            />
          )}
          <AddIcon onClick={addNewRoute} />
        </div>
      )}
    </Wrapper>
  );
}

export default SubRoutingContainer;
