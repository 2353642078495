const logsReducer = (state = { logs: null }, action) => {
  switch (action.type) {
    case "GET_LOGS":
      let logsCurrentPage = action?.payload?.currentPage;
      let logsNumberOfPages = action?.payload?.numberOfPages;
      return {
        ...state,
        logs: action?.payload?.logs || [],
        logsCurrentPage,
        logsNumberOfPages,
      };
    default:
      return state;
  }
};

export default logsReducer;
