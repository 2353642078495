import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-height: 250px;
  min-height: 250px;
  background-color: var(--mainHoverColor);
  padding: 6px;
  overflow: hidden;
  overflow-y: auto;
  border-radius: 6px;
  align-content: start;
  justify-content: center;
`;

export const IconWrapper = styled.div`
  padding: 2px;
  height: var(--extraExtraLargeIconSize);
  width: var(--extraExtraLargeIconSize);
  border-radius: 6px;
  background: var(--mainColor);
  margin: 2px;
  display: flex;
  align-items: center;
  align-content: center;
  justify-items: center;
  justify-content: center;
  overflow: hidden;
  border: 3px solid var(--thirdColor);

  &:hover {
    cursor: pointer;
    border-color: var(--mainIconHoverColor);
  }
`;
