export const ToggleLeftNav = () => {
  return {
    type: "TOGGLE_LEFTNAV",
  };
};

export const toggleAdminEditor = (path) => {
  return {
    type: "TOGGLE_ADMIN_EDITOR",
  };
};

export const toggleSettingsNav = () => {
  return {
    type: "TOGGLE_SETTINGSNAV",
  };
};
