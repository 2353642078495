const nyhetsReducer = (state = [], action) => {
  switch (action.type) {
    case "FETCH_NYHETS_POSTS":
      return {
        ...state,
        nyheter: action.payload.data,
        currentPage: action.payload.currentPage,
        numberOfPages: action.payload.numberOfPages,
      };
    case "CREATE_NYHETS_POST":
      let nyheter = state.nyheter;
      if (nyheter.length !== 0) {
        nyheter.unshift(action.payload);
      } else {
        nyheter = action.payload;
      }
      return {
        ...state,
        nyheter: Array.isArray(nyheter) ? [...nyheter] : [nyheter],
      };
    case "DELETE_NYHETS_POST":
      return {
        ...state,
        nyheter: state.nyheter.filter((nyhet) => nyhet._id !== action.payload),
      };
    case "UPDATE_NYHETS_POST":
      return {
        ...state,
        nyheter: state.nyheter.map((nyhet) =>
          nyhet._id === action.payload._id ? action.payload : nyhet
        ),
      };
    default:
      return state;
  }
};

export default nyhetsReducer;
