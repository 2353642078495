import styled from "styled-components";
import { Link } from "react-router-dom";

import { SignOut } from "../../../Icons/Icon";

export const IconContainer = styled.div`
  padding: 2px 2px 2px 0px;
  margin-right: 5px;
  position: relative;
  float: left;
  height: var(--mediumIconSize);
  width: var(--mediumIconSize);
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 15px;
`;

export const Button = styled(Link)`
  padding-left: 10px;
  align-items: start;
  text-decoration: none;
  font-size: var(--thirdHeadingSize);
  color: var(--mainTextColor);
  width: 95%;
  border-radius: 6px;
  justify-content: space-between;
  margin: 5px 0px;

  &:hover {
    color: var(--mainTextColor);
    background-color: var(--mainHoverColor);
    cursor: pointer;
  }
`;

export const SignOutIcon = styled(SignOut)`
  height: var(--thirdHeadingSize);
  width: var(--thirdHeadingSize);
  margin-right: 5px;
  text-align: center;
  justify-content: center;
`;


