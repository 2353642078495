import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {
  Wrapper,
  Checkbox,
  CheckboxContainer,
  CheckboxLabel,
  Heading,
  InputText,
  SubmitButton,
  CancelButton,
  IconHeader,
  ButtonContainer,
} from "../RoutingCreator/styles";
import { createRoute, createSubRoute } from "../../actions/routes";
import IconContainer from "../IconContainer/IconContainer";

function RoutingCreator({
  parentId,
  hideRoutingContainer,
  handlingSubRoute = false,
}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const defaultValue = {
    name: "",
    title: false,
    description: false,
    link: false,
    category: false,
    tags: false,
    fileUpload: false,
    subRoute: false,
    hasIcon: false,
  };
  const [routingData, setRoutingData] = useState(defaultValue);
  const [disableButton, setDisableButton] = useState(true);
  const [selectedIcon, setSelectedIcon] = useState(null);
  const [loggedInUser, setLoggedInUser] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (parentId) {
      //If we have parentId it means that we are creating a subroute connected to the parentId
      dispatch(
        createSubRoute(
          parentId,
          {
            route: { ...routingData, parentRoute: parentId },
            user: loggedInUser,
          },
          history
        )
      );
      //setTimeout(() => dispatch(getRoutes(history)), [750]);
    } else {
      dispatch(
        createRoute({ route: routingData, user: loggedInUser }, history)
      );
    }
    setRoutingData(defaultValue);
  };

  const cancel = () => {
    setRoutingData(defaultValue);
    hideRoutingContainer();
  };

  useEffect(() => {
    isButtonDisabled();
  }, [routingData]);

  useEffect(() => {
    setLoggedInUser(JSON.parse(localStorage.getItem("user")));
  }, [location]);

  const isButtonDisabled = () => {
    let disabled =
      (routingData.title ||
        routingData.description ||
        routingData.link ||
        routingData.category ||
        routingData.tags ||
        routingData.subRoute ||
        routingData.hasIcon ||
        routingData.fileUpload) == false || routingData.name == "";
    setDisableButton(disabled);
  };

  useEffect(() => {
    if (selectedIcon != null && routingData.hasIcon == true) {
      routingData.icon = selectedIcon;
    } else if (
      selectedIcon != null &&
      handlingSubRoute &&
      routingData.hasIcon
    ) {
      routingData.icon = selectedIcon;
    } else {
      delete routingData.icon;
    }
  }, [selectedIcon, routingData.subRoute]);

  return (
    <Wrapper>
      <form autoCapitalize="off" noValidate onSubmit={handleSubmit}>
        <Heading>Välj vilka fält som ska fyllas i vid uppladdning.</Heading>
        <InputText
          placeholder={"Namn på kategori*"}
          type="input"
          value={routingData.name}
          onChange={(e) =>
            setRoutingData({ ...routingData, name: e.target.value })
          }
        ></InputText>
        <CheckboxContainer>
          <Checkbox
            type="checkbox"
            checked={routingData.title}
            onClick={() => {
              let prevState = routingData.title;
              setRoutingData({ ...routingData, title: !prevState });
            }}
          />
          <CheckboxLabel>Titel</CheckboxLabel>
        </CheckboxContainer>
        <CheckboxContainer>
          <Checkbox
            type="checkbox"
            checked={routingData.description}
            onClick={() => {
              let prevState = routingData.description;
              setRoutingData({ ...routingData, description: !prevState });
            }}
          />
          <CheckboxLabel>Beskrivning</CheckboxLabel>
        </CheckboxContainer>
        <CheckboxContainer>
          <Checkbox
            type="checkbox"
            checked={routingData.link}
            onClick={() => {
              let prevState = routingData.link;
              setRoutingData({ ...routingData, link: !prevState });
            }}
          />
          <CheckboxLabel>Länk</CheckboxLabel>
        </CheckboxContainer>
        <CheckboxContainer>
          <Checkbox
            type="checkbox"
            checked={routingData.category}
            onClick={() => {
              let prevState = routingData.category;
              setRoutingData({ ...routingData, category: !prevState });
            }}
          />
          <CheckboxLabel>Kategori</CheckboxLabel>
        </CheckboxContainer>
        <CheckboxContainer>
          <Checkbox
            type="checkbox"
            checked={routingData.tags}
            onClick={() => {
              let prevState = routingData.tags;
              setRoutingData({ ...routingData, tags: !prevState });
            }}
          />
          <CheckboxLabel>Taggar</CheckboxLabel>
        </CheckboxContainer>
        {/*<CheckboxContainer>
          <Checkbox
            type="checkbox"
            checked={routingData.fileUpload}
            onClick={() => {
              let prevState = routingData.fileUpload;
              setRoutingData({ ...routingData, fileUpload: !prevState });
            }}
          />
          <CheckboxLabel>Filuppladning</CheckboxLabel>
          </CheckboxContainer>*/}
        <CheckboxContainer>
          <Checkbox
            type="checkbox"
            checked={routingData.hasIcon}
            onClick={() => {
              let prevState = routingData.hasIcon;
              setRoutingData({ ...routingData, hasIcon: !prevState });
            }}
          />
          <CheckboxLabel>Skall ha ikon</CheckboxLabel>
        </CheckboxContainer>
        {!handlingSubRoute && (
          <CheckboxContainer>
            <Checkbox
              type="checkbox"
              checked={routingData.subRoute}
              onClick={() => {
                let prevState = routingData.subRoute;
                setRoutingData({ ...routingData, subRoute: !prevState });
              }}
            />
            <CheckboxLabel>Skall ha underkategori</CheckboxLabel>
          </CheckboxContainer>
        )}

        {routingData.hasIcon && (
          <IconHeader>
            Välj en ikon (Lämna tomt om ingen ikon önskas)
          </IconHeader>
        )}
        {routingData.hasIcon && (
          <IconContainer setSelectedIcon={setSelectedIcon} />
        )}

        <ButtonContainer>
          <SubmitButton disabled={disableButton} type="submit">
            Skapa Route
          </SubmitButton>
          <CancelButton onClick={cancel}>Avbryt</CancelButton>
        </ButtonContainer>
      </form>
    </Wrapper>
  );
}

export default RoutingCreator;
