import styled from "styled-components";
import { AddPlus } from "../../Icons/Icon";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const AddIcon = styled(AddPlus)`
  height: var(--extraExtraLargeIconSize);
  align-self: end;
  margin-top: 15px;
  padding: 4px;
  border-radius: 50%;
  fill: var(--thirdColor);
  color: var(--mainColor);

  &:hover {
    fill: var(--mainIconHoverColor);
    cursor: pointer;
  }

  &:disabled {
    cursor: default;
    bottom: 0;
    opacity: 0.65;

    &:hover {
      background-color: var(--secondaryColor);
    }
  }
`;
