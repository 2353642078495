const cronReducer = (state = { dbCrons: [] }, action) => {
  switch (action.type) {
    case "CREATE_NEW_CRON": {
      return { ...state };
    }
    case "GET_DB_CRONS": {
      const { data = [] } = action.payload;
      return {
        ...state,
        dbCrons: data,
      };
    }
    case "UPDATE_CRON": {
      const { data = {} } = action.payload;
      const index = state.dbCrons.findIndex((cron) => cron._id === data._id);
      const newDbCrons = [...state.dbCrons];
      newDbCrons[index] = data;
      return {
        ...state,
        dbCrons: newDbCrons,
      };
    }
    case "DELETE_CRON": {
      const { data = {} } = action.payload;

      const index = state.dbCrons.findIndex((cron) => cron._id === data);
      if (index > -1) {
        const newDbCrons = [...state.dbCrons];
        newDbCrons[index] = null; //Set value of cron to null
        newDbCrons.splice(index); //Delete the cron from the list
        return {
          ...state,
          dbCrons: newDbCrons,
        };
      }
      return { ...state };
    }
    default:
      return state;
  }
};

export default cronReducer;
