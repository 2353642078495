import React, { useEffect, useState } from "react";
import { Wrapper, Button, SignOutIcon, IconContainer } from "./styles";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { setActiveTab } from "../../../actions/global";
import { ToggleLeftNav } from "../../../actions/navbar";
import ContainerDivider from "../../ContainerDivider/ContainerDivider";
import { getLogs } from "../../../actions/logs";

function SettingsContainer({ link = "/login-admin" }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [user, setUser] = useState(null);
  const [activeNow, setActiveNow] = useState(false);
  const activeTab = useSelector((state) => state.globalReducer.activeTab);

  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem("user")));
  }, [location]);

  const handleClick = (value) => {
    dispatch(ToggleLeftNav());
    setActiveTab(value);
    dispatch(setActiveTab(value));
    if (value == "/login-admin") {
      if (user) {
        dispatch({ type: "AUTH_LOGOUT" });
        setUser(null);
      }
    }
  };

  return (
    <Wrapper>
      <ContainerDivider />
      {user ? (
        <Button
          onClick={() => handleClick("/installningar")}
          style={{
            backgroundColor:
              activeTab == "/installningar"
                ? getComputedStyle(document.documentElement).getPropertyValue(
                    "--mainHoverColor"
                  )
                : "",
          }}
          to="/installningar"
        >
          Inställningar
        </Button>
      ) : null}

      <Button
        style={{
          backgroundColor:
            activeTab == "/login-admin"
              ? getComputedStyle(document.documentElement).getPropertyValue(
                  "--mainHoverColor"
                )
              : "",
        }}
        to="/login-admin"
        onClick={() => handleClick("/login-admin")}
      >
        <IconContainer>
          <SignOutIcon />
        </IconContainer>
        {user ? "Logga ut" : "Logga in"}
      </Button>
    </Wrapper>
  );
}

export default SettingsContainer;
