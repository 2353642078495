import styled from "styled-components";
import { Link } from "react-router-dom";
import { BarsIcon, SettingsIcon, Times } from "../../Icons/Icon";

export const TopNav = styled.div`
  display: flex;
  height: 55px;
  background-color: var(--secondaryColor);
  position: fixed;
  width: 100vw;
  z-index: 2;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.6);
  padding: 0px 20px;
  align-items: center;
  justify-content: space-between;
`;

export const LoginIcon = styled(Link)``;

export const LeftWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const LogoText = styled.span`
  font-size: var(--mainFontSize);
  letter-spacing: 1.5px;
  font-style: italic;
  margin-left: 15px;

  &:hover {
    cursor: default;
    user-select: none;
  }
`;

export const LogoImage = styled.img`
  position: relative;
  margin-left: 25px;
  height: 42px;
  width: auto;
  align-self: center;
`;

export const HamburgerIcon = styled(BarsIcon)`
  height: var(--mainHeadingSize);
  fill: var(--mainTextColor);

  &:hover {
    cursor: pointer;
    fill: var(--mainHoverColor);
  }
`;

export const CloseIcon = styled(Times)`
  height: var(--mainHeadingSize);
  fill: var(--mainTextColor);

  &:hover {
    cursor: pointer;
    fill: var(--mainHoverColor);
  }
`;

export const UserIcon = styled(SettingsIcon)`
  height: var(--mainHeadingSize);
  fill: var(--mainTextColor);

  &:hover {
    cursor: pointer;
    fill: var(--mainHoverColor);
  }
`;

export const RightWrapper = styled.div`
  display: flex;
  align-items: center;
`;
