const integrationsReducer = (
  state = { integrationsWithConfig: [], dbIntegrations: [] },
  action
) => {
  switch (action.type) {
    case "GET_INTEGRATIONS_WITH_CONFIG":
      const { data = [] } = action.payload;
      return {
        ...state,
        integrationsWithConfig: data,
      };
    case "CREATE_NEW_INTEGRATION": {
      return { ...state };
    }
    case "GET_DB_INTEGRATIONS": {
      const { data = [] } = action.payload;
      return { ...state, dbIntegrations: data };
    }
    case "UPDATE_INTEGRATION": {
      const { data = {} } = action.payload;
      const index = state.dbIntegrations.findIndex((e) => e._id === data._id);
      const newDbIntegrations = [...state.dbIntegrations];
      newDbIntegrations[index] = data;

      return { ...state, dbIntegrations: newDbIntegrations };
    }

    case "DELETE_INTEGRATION": {
      const { data = {} } = action.payload;

      const index = state.dbIntegrations.findIndex(
        (integration) => integration._id === data
      );
      if (index > -1) {
        const newDbIntegrations = [...state.dbIntegrations];
        newDbIntegrations[index] = null; //Set value of cron to null
        newDbIntegrations.splice(index); //Delete the cron from the list
        return {
          ...state,
          dbIntegrations: newDbIntegrations,
        };
      }
      return {
        ...state,
      };
    }
    default:
      return state;
  }
};

export default integrationsReducer;
