import React from "react";
import { Divider, Wrapper } from "./styles";

function ContainerDivider() {
  return (
    <Wrapper>
      <Divider></Divider>
    </Wrapper>
  );
}

export default ContainerDivider;
