import React from "react";
import { ReactComponent as TrashIcon } from "../svgs/regular/trash-alt.svg";
import { ReactComponent as SettingsIcon } from "../svgs/regular/cog.svg";
import { ReactComponent as BarsIcon } from "../svgs/regular/bars.svg";
import { ReactComponent as Ellipsis } from "../svgs/regular/ellipsis-v.svg";
import { ReactComponent as AddPlus } from "../svgs/regular/plus-circle.svg";
import { ReactComponent as Times } from "../svgs/regular/times.svg";
import { ReactComponent as VectorSquare } from "../svgs/regular/vector-square.svg";
import { ReactComponent as ForkAndKnife } from "../svgs/regular/utensils.svg";
import { ReactComponent as ForkAndKnifeAlt } from "../svgs/regular/utensils-alt.svg";
import { ReactComponent as Spoon } from "../svgs/regular/utensil-spoon.svg";
import { ReactComponent as Knife } from "../svgs/regular/utensil-knife.svg";
import { ReactComponent as Fork } from "../svgs/regular/utensil-fork.svg";
import { ReactComponent as Users } from "../svgs/regular/users.svg";
import { ReactComponent as UserSecret } from "../svgs/regular/user-secret.svg";
import { ReactComponent as UserRobot } from "../svgs/regular/user-robot.svg";
import { ReactComponent as UserNurse } from "../svgs/regular/user-nurse.svg";
import { ReactComponent as UserNinja } from "../svgs/regular/user-ninja.svg";
import { ReactComponent as UserMusic } from "../svgs/regular/user-music.svg";
import { ReactComponent as UserLock } from "../svgs/regular/user-lock.svg";
import { ReactComponent as UserInjured } from "../svgs/regular/user-injured.svg";
import { ReactComponent as UserHeadset } from "../svgs/regular/user-headset.svg";
import { ReactComponent as UserHardhat } from "../svgs/regular/user-hard-hat.svg";
import { ReactComponent as UserGraduate } from "../svgs/regular/user-graduate.svg";
import { ReactComponent as UserCrown } from "../svgs/regular/user-crown.svg";
import { ReactComponent as UserCowboy } from "../svgs/regular/user-cowboy.svg";
import { ReactComponent as UserCircle } from "../svgs/regular/user-circle.svg";
import { ReactComponent as UserChart } from "../svgs/regular/user-chart.svg";
import { ReactComponent as UserAstronout } from "../svgs/regular/user-astronaut.svg";
import { ReactComponent as UserAlt } from "../svgs/regular/user-alt.svg";
import { ReactComponent as UserAlien } from "../svgs/regular/user-alien.svg";
import { ReactComponent as Unlock } from "../svgs/regular/unlock.svg";
import { ReactComponent as UnlockAlt } from "../svgs/regular/unlock-alt.svg";
import { ReactComponent as Unicorn } from "../svgs/regular/unicorn.svg";
import { ReactComponent as Ufo } from "../svgs/regular/ufo.svg";
import { ReactComponent as UfoBeam } from "../svgs/regular/ufo-beam.svg";
import { ReactComponent as Turtle } from "../svgs/regular/turtle.svg";
import { ReactComponent as Triangle } from "../svgs/regular/triangle.svg";
import { ReactComponent as Trees } from "../svgs/regular/trees.svg";
import { ReactComponent as Tree } from "../svgs/regular/tree.svg";
import { ReactComponent as Tools } from "../svgs/regular/tools.svg";
import { ReactComponent as TimesSquare } from "../svgs/regular/times-square.svg";
import { ReactComponent as TimesOctagon } from "../svgs/regular/times-octagon.svg";
import { ReactComponent as TimesHexagon } from "../svgs/regular/times-hexagon.svg";
import { ReactComponent as TimesCircle } from "../svgs/regular/times-circle.svg";
import { ReactComponent as Thunderstorm } from "../svgs/regular/thunderstorm.svg";
import { ReactComponent as ThumbsUp } from "../svgs/regular/thumbs-up.svg";
import { ReactComponent as ThumbsDown } from "../svgs/regular/thumbs-down.svg";
import { ReactComponent as TheaterMasks } from "../svgs/regular/theater-masks.svg";
import { ReactComponent as Tasks } from "../svgs/regular/tasks.svg";
import { ReactComponent as Tally } from "../svgs/regular/tally.svg";
import { ReactComponent as Tag } from "../svgs/regular/tag.svg";
import { ReactComponent as Tags } from "../svgs/regular/tags.svg";
import { ReactComponent as Sync } from "../svgs/regular/sync.svg";
import { ReactComponent as SyncAlt } from "../svgs/regular/sync-alt.svg";
import { ReactComponent as Signal } from "../svgs/regular/signal.svg";
import { ReactComponent as SignalAlt } from "../svgs/regular/signal-alt.svg";
import { ReactComponent as Sigma } from "../svgs/regular/sigma.svg";
import { ReactComponent as ShoppingCart } from "../svgs/regular/shopping-cart.svg";
import { ReactComponent as ShareAlt } from "../svgs/regular/share-alt.svg";
import { ReactComponent as Search } from "../svgs/regular/search.svg";
import { ReactComponent as SearchPlus } from "../svgs/regular/search-plus.svg";
import { ReactComponent as SearchMinus } from "../svgs/regular/search-minus.svg";
import { ReactComponent as SerachDollar } from "../svgs/regular/search-dollar.svg";
import { ReactComponent as Save } from "../svgs/regular/save.svg";
import { ReactComponent as Robot } from "../svgs/regular/robot.svg";
import { ReactComponent as ReactangleWide } from "../svgs/regular/rectangle-wide.svg";
import { ReactComponent as RectanglePortrait } from "../svgs/regular/rectangle-portrait.svg";
import { ReactComponent as Rabbit } from "../svgs/regular/rabbit.svg";
import { ReactComponent as RabbitFast } from "../svgs/regular/rabbit-fast.svg";
import { ReactComponent as QuoteRight } from "../svgs/regular/quote-right.svg";
import { ReactComponent as QuoteLeft } from "../svgs/regular/quote-left.svg";
import { ReactComponent as QuestionSquare } from "../svgs/regular/question-square.svg";
import { ReactComponent as QuestionCircle } from "../svgs/regular/question-circle.svg";
import { ReactComponent as Puzzle } from "../svgs/regular/puzzle-piece.svg";
import { ReactComponent as ProjectDiagram } from "../svgs/regular/project-diagram.svg";
import { ReactComponent as Popcorn } from "../svgs/regular/popcorn.svg";
import { ReactComponent as Poop } from "../svgs/regular/poop.svg";
import { ReactComponent as Poo } from "../svgs/regular/poo.svg";
import { ReactComponent as Poll } from "../svgs/regular/poll-h.svg";
import { ReactComponent as Play } from "../svgs/regular/play.svg";
import { ReactComponent as PlanetRinged } from "../svgs/regular/planet-ringed.svg";
import { ReactComponent as PlanetMoon } from "../svgs/regular/planet-moon.svg";
import { ReactComponent as Paw } from "../svgs/regular/paw.svg";
import { ReactComponent as PawClaws } from "../svgs/regular/paw-claws.svg";
import { ReactComponent as PawAlt } from "../svgs/regular/paw-alt.svg";
import { ReactComponent as PaperClip } from "../svgs/regular/paperclip.svg";
import { ReactComponent as PaperPlane } from "../svgs/regular/paper-plane.svg";
import { ReactComponent as Palette } from "../svgs/regular/palette.svg";
import { ReactComponent as Music } from "../svgs/regular/music.svg";
import { ReactComponent as Mountains } from "../svgs/regular/mountains.svg";
import { ReactComponent as LayerGroup } from "../svgs/regular/layer-group.svg";
import { ReactComponent as SmileyLaugh } from "../svgs/regular/laugh.svg";
import { ReactComponent as SmileyWink } from "../svgs/regular/laugh-wink.svg";
import { ReactComponent as SmileySquint } from "../svgs/regular/laugh-squint.svg";
import { ReactComponent as SmileyBeam } from "../svgs/regular/laugh-beam.svg";
import { ReactComponent as SmileyKiss } from "../svgs/regular/kiss.svg";
import { ReactComponent as SmileyKissWink } from "../svgs/regular/kiss-wink-heart.svg";
import { ReactComponent as SmileyKissBeam } from "../svgs/regular/kiss-beam.svg";
import { ReactComponent as Key } from "../svgs/regular/key.svg";
import { ReactComponent as KeySkeleton } from "../svgs/regular/key-skeleton.svg";
import { ReactComponent as Hourglass } from "../svgs/regular/hourglass.svg";
import { ReactComponent as House } from "../svgs/regular/house.svg";
import { ReactComponent as Horse } from "../svgs/regular/horse.svg";
import { ReactComponent as HorseHead } from "../svgs/regular/horse-head.svg";
import { ReactComponent as Heart } from "../svgs/regular/heart.svg";
import { ReactComponent as HeartBeat } from "../svgs/regular/heartbeat.svg";
import { ReactComponent as Hashtag } from "../svgs/regular/hashtag.svg";
import { ReactComponent as ExternalLink } from "../svgs/regular/external-link.svg";
import { ReactComponent as SignOut } from "../svgs/regular/sign-out.svg";
import { ReactComponent as SignOutAlt } from "../svgs/regular/sign-out-alt.svg";
import { ReactComponent as CaretSquareDown } from "../svgs/regular/caret-square-down.svg";
import { ReactComponent as ArrowRight } from "../svgs/regular/arrow-right.svg";
import { ReactComponent as CaretSquareUp } from "../svgs/regular/caret-square-up.svg";
import { ReactComponent as CalendarAlt } from "../svgs/regular/calendar-alt.svg";
import { ReactComponent as CaretRight } from "../svgs/solid/caret-right.svg";
import { ReactComponent as CaretDown } from "../svgs/solid/caret-down.svg";

const height = getComputedStyle(document.documentElement).getPropertyValue(
  "--thirdHeadingSize"
);
const width = height;
//Test

//Very important to never change the order of these since it will affect how they are displayed in client.
const AllIcons = [
  <TrashIcon width={width} height={height} />,
  <SettingsIcon width={width} height={height} />,
  <BarsIcon width={width} height={height} />,
  <Ellipsis width={width} height={height} />,
  <AddPlus width={width} height={height} />,
  <Times width={width} height={height} />,
  <VectorSquare width={width} height={height} />,
  <ForkAndKnife width={width} height={height} />,
  <ForkAndKnifeAlt width={width} height={height} />,
  <UserSecret width={width} height={height} />,
  <UserRobot width={width} height={height} />,
  <UserNurse width={width} height={height} />,
  <UserNinja width={width} height={height} />,
  <UserMusic width={width} height={height} />,
  <UserLock width={width} height={height} />,
  <UserInjured width={width} height={height} />,
  <UserHeadset width={width} height={height} />,
  <UserHardhat width={width} height={height} />,
  <UserGraduate width={width} height={height} />,
  <UserCrown width={width} height={height} />,
  <UserCowboy width={width} height={height} />,
  <UserCircle width={width} height={height} />,
  <UserChart width={width} height={height} />,
  <UserAstronout width={width} height={height} />,
  <UserAlt width={width} height={height} />,
  <UserAlien width={width} height={height} />,
  <Unlock width={width} height={height} />,
  <UnlockAlt width={width} height={height} />,
  <Unicorn width={width} height={height} />,
  <Ufo width={width} height={height} />,
  <UfoBeam width={width} height={height} />,
  <Turtle width={width} height={height} />,
  <Triangle width={width} height={height} />,
  <Trees width={width} height={height} />,
  <Tree width={width} height={height} />,
  <Tools width={width} height={height} />,
  <TimesSquare width={width} height={height} />,
  <TimesOctagon width={width} height={height} />,
  <TimesHexagon width={width} height={height} />,
  <TimesCircle width={width} height={height} />,
  <Thunderstorm width={width} height={height} />,
  <ThumbsUp width={width} height={height} />,
  <ThumbsDown width={width} height={height} />,
  <TheaterMasks width={width} height={height} />,
  <Tasks width={width} height={height} />,
  <Tally width={width} height={height} />,
  <Tag width={width} height={height} />,
  <Tags width={width} height={height} />,
  <Sync width={width} height={height} />,
  <SyncAlt width={width} height={height} />,
  <Signal width={width} height={height} />,
  <SignalAlt width={width} height={height} />,
  <Sigma width={width} height={height} />,
  <ShoppingCart width={width} height={height} />,
  <ShareAlt width={width} height={height} />,
  <Search width={width} height={height} />,
  <SearchPlus width={width} height={height} />,
  <SearchMinus width={width} height={height} />,
  <SerachDollar width={width} height={height} />,
  <Save width={width} height={height} />,
  <Robot width={width} height={height} />,
  <ReactangleWide width={width} height={height} />,
  <RectanglePortrait width={width} height={height} />,
  <Rabbit width={width} height={height} />,
  <RabbitFast width={width} height={height} />,
  <QuoteRight width={width} height={height} />,
  <QuoteLeft width={width} height={height} />,
  <QuestionSquare width={width} height={height} />,
  <QuestionCircle width={width} height={height} />,
  <Puzzle width={width} height={height} />,
  <ProjectDiagram width={width} height={height} />,
  <Popcorn width={width} height={height} />,
  <Poop width={width} height={height} />,
  <Poo width={width} height={height} />,
  <Poll width={width} height={height} />,
  <Play width={width} height={height} />,
  <PlanetRinged width={width} height={height} />,
  <PlanetMoon width={width} height={height} />,
  <Paw width={width} height={height} />,
  <PawClaws width={width} height={height} />,
  <PawAlt width={width} height={height} />,
  <PaperClip width={width} height={height} />,
  <PaperPlane width={width} height={height} />,
  <Palette width={width} height={height} />,
  <Music width={width} height={height} />,
  <Mountains width={width} height={height} />,
  <LayerGroup width={width} height={height} />,
  <SmileyLaugh width={width} height={height} />,
  <SmileyWink width={width} height={height} />,
  <SmileySquint width={width} height={height} />,
  <SmileyBeam width={width} height={height} />,
  <SmileyKiss width={width} height={height} />,
  <SmileyKissWink width={width} height={height} />,
  <SmileyKissBeam width={width} height={height} />,
  <Key width={width} height={height} />,
  <KeySkeleton width={width} height={height} />,
  <Hourglass width={width} height={height} />,
  <House width={width} height={height} />,
  <Horse width={width} height={height} />,
  <HorseHead width={width} height={height} />,
  <Heart width={width} height={height} />,
  <HeartBeat width={width} height={height} />,
  <Hashtag width={width} height={height} />,
  <ExternalLink width={width} height={height} />,
  <SignOut width={width} height={height} />,
  <SignOutAlt width={width} height={height} />,
  <CaretSquareUp width={width} height={height} />,
  <CaretSquareDown width={width} height={height} />,
  <ArrowRight width={width} height={height} />,
  <CalendarAlt width={width} height={height} />,
  <CaretRight width={width} height={height} />,
  <CaretDown width={width} height={height} />,
];

export {
  TrashIcon,
  SettingsIcon,
  BarsIcon,
  Ellipsis,
  AddPlus,
  Times,
  VectorSquare,
  ForkAndKnife,
  ForkAndKnifeAlt,
  UserSecret,
  UserRobot,
  UserNurse,
  UserNinja,
  UserMusic,
  UserLock,
  UserInjured,
  UserHeadset,
  UserHardhat,
  UserGraduate,
  UserCrown,
  UserCowboy,
  UserCircle,
  UserChart,
  UserAstronout,
  UserAlt,
  UserAlien,
  Unlock,
  UnlockAlt,
  Unicorn,
  Ufo,
  UfoBeam,
  Turtle,
  Triangle,
  Trees,
  Tree,
  Tools,
  TimesSquare,
  TimesOctagon,
  TimesHexagon,
  TimesCircle,
  Thunderstorm,
  ThumbsUp,
  ThumbsDown,
  TheaterMasks,
  Tasks,
  Tally,
  Tag,
  Tags,
  Sync,
  SyncAlt,
  Signal,
  SignalAlt,
  Sigma,
  ShoppingCart,
  ShareAlt,
  Search,
  SearchPlus,
  SearchMinus,
  SerachDollar,
  Save,
  Robot,
  ReactangleWide,
  RectanglePortrait,
  Rabbit,
  RabbitFast,
  QuoteRight,
  QuoteLeft,
  QuestionSquare,
  QuestionCircle,
  Puzzle,
  ProjectDiagram,
  Popcorn,
  Poop,
  Poo,
  Poll,
  Play,
  PlanetRinged,
  PlanetMoon,
  Paw,
  PawClaws,
  PawAlt,
  PaperClip,
  PaperPlane,
  Palette,
  Music,
  Mountains,
  LayerGroup,
  SmileyLaugh,
  SmileyWink,
  SmileySquint,
  SmileyBeam,
  SmileyKiss,
  SmileyKissWink,
  SmileyKissBeam,
  Key,
  KeySkeleton,
  Hourglass,
  House,
  Horse,
  HorseHead,
  Heart,
  HeartBeat,
  Hashtag,
  ExternalLink,
  SignOut,
  SignOutAlt,
  CaretSquareUp,
  CaretSquareDown,
  ArrowRight,
  CalendarAlt,
  CaretDown,
  CaretRight,
  AllIcons,
};
