import { combineReducers } from "redux";

import navbarReducer from "./navbar";
import globalReducer from "./global";
import authAdminReducer from "./authAdmin";
import nyhetsReducer from "./nyheter";
import routingReducer from "./routes";
import logsReducer from "./logs";
import integrationsReducer from "./integrations";
import cronReducer from "./cron";

export default combineReducers({
  navbarReducer,
  globalReducer,
  authAdminReducer,
  nyhetsReducer,
  routingReducer,
  logsReducer,
  integrationsReducer,
  cronReducer,
});
