const navbarReducer = (
  state = {
    showLeftNav: false,
    showAdminEditor: false,
    showSettingsNav: false,
  },
  action
) => {
  switch (action.type) {
    case "TOGGLE_LEFTNAV":
      return { ...state, showLeftNav: !state.showLeftNav };
    //case "TOGGLE_TOPNAV":
    //  return state;
    case "TOGGLE_ADMIN_EDITOR":
      return { ...state, showAdminEditor: !state.showAdminEditor };
    case "TOGGLE_SETTINGSNAV":
      return { ...state, showSettingsNav: !state.showSettingsNav };
    default:
      return state;
  }
};

export default navbarReducer;
