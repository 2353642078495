import * as api from "../api/index";

export const setActiveTab = (path) => {
  return {
    type: "SET_ACTIVE_TAB",
    payload: path,
  };
};

export const setActiveRoutePostEditor = (val) => {
  return {
    type: "SET_ACTIVE_ROUTEPOST_EDITOR",
    payload: val,
  };
};

export const setBlogPostCreatorData = (data) => {
  return {
    type: "SET_BLOGPOST_CREATOR_DATA",
    payload: data,
  };
};

export const setRoutingCreatorData = (data) => {
  return {
    type: "SET_ROUTING_CREATOR_DATA",
    payload: data,
  };
};

export const setWaitingUpload = (value) => {
  return {
    type: "SET_WAITING_UPLOAD",
    payload: value,
  };
};

export const setRoutePostsFilter = (value) => {
  return {
    type: "SET_ROUTE_POSTS_FILTER",
    payload: value,
  };
};

export const setSubRoutePostsFilter = (value) => {
  return {
    type: "SET_SUB_ROUTE_POSTS_FILTER",
    payload: value,
  };
};
