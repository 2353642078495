import React, { useState, useEffect } from "react";
import {
  TopNav,
  HamburgerIcon,
  RightWrapper,
  UserIcon,
  LeftWrapper,
  LogoText,
  LogoImage,
  LoginIcon,
  CloseIcon,
} from "./styles";
import { ToggleLeftNav } from "../../actions/navbar";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import decode from "jwt-decode";

function TopNavBar() {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [loggedInUser, setLoggedInUser] = useState(null);
  const activeNav = useSelector((state) => state.navbarReducer.showLeftNav);

  useEffect(() => {
    const token = loggedInUser?.token;

    if (token) {
      const decodedToken = decode(token);
      if (decodedToken.exp * 1000 < new Date().getTime()) {
        dispatch({ type: "AUTH_LOGOUT" });
        history.push("/");
      }
    }
  }, [location]);

  useEffect(() => {
    setLoggedInUser(JSON.parse(localStorage.getItem("user")));
  }, [location]);

  const toggleLeftNav = () => {
    dispatch(ToggleLeftNav());
  };

  return (
    <TopNav>
      {activeNav ? (
        <CloseIcon onClick={toggleLeftNav} />
      ) : (
        <HamburgerIcon onClick={toggleLeftNav} />
      )}
      <LogoImage src="/images/isparlanLogga.gif" />
      <div style={{ width: "24.5px" }} />
    </TopNav>
  );
}

export default TopNavBar;
