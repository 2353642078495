import styled from "styled-components";
import { Link } from "react-router-dom";

export const LinkItem = styled(Link)`
  padding-left: 10px;
  align-items: start;
  text-decoration: none;
  font-size: var(--thirdHeadingSize);
  margin: 5px 0px;
  color: var(--mainTextColor);
  width: 95%;
  border-radius: 6px;
  font-weight: 500;

  &:hover {
    color: var(--mainTextColor);
    background-color: var(--mainHoverColor);
  }
`;

export const Icon = styled.i`
  margin-right: 10px;
  font-size: var(--mainFontSize);
  color: var(--mainTextColor);
  transition: 0.35s;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
