import styled, { keyframes } from "styled-components";
import { device } from "../styles/devices";

export const Wrapper = styled.div``;

export const Container = styled.div`
  margin: 0px 0px 0px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  text-align: center;
  overflow: hidden;
`;

const slide = keyframes`
    from {
    background-position-x: 0;
    }
    to {
        background-position-x: calc(246px * 2);
    }
`;

export const Bar = styled.div`
  min-height: 18px;
  border-radius: 5px;
  width: 100%;

  @media ${device.mobileS} {
    width: 100%;
  }

  @media ${device.laptop} {
    width: 100%;
  }

  //box-shadow: 0px 10px 13px -6px rgba(44, 62, 80, 1);
  background-color: var(--secondaryColor);
  background-image: repeating-linear-gradient(
    -45deg,
    transparent,
    transparent 40px,
    var(--mainHoverColor) 80px,
    var(--thirdColor) 80px
  );
  animation: ${slide} 4s linear infinite;
  will-change: background-position;
`;

export const TextContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
  align-items: center;
`;

export const Text = styled.span`
  font-size: var(--fourthFontSize);
  color: var(--mainTextColor);
  font-weight: 500;
`;
