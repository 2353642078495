import styled from "styled-components";
import FileBase64 from "react-file-base64";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  border-radius: 6px;
  background-color: var(--thirdColor);
  width: 95%;
  align-self: center;
  overflow: hidden;
  transition: 0.35s;
  margin: 5px 0px;
`;

export const Selector = styled.select``;

export const SelectValue = styled.option``;

export const SelectorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
`;

export const MinifiedWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
`;

export const SelectorLabel = styled.label`
  font-size: var(--thirdFontSize);
`;

export const InputContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const FileInput2 = styled.input`
  color: transparent;
`;
