const authAdminReducer = (state = { authAdminData: null }, action) => {
  switch (action.type) {
    case "AUTH_ADMIN":
      localStorage.setItem("user", JSON.stringify({ ...action?.data }));
      return { ...state, authAdminData: action?.data };
    case "AUTH_LOGOUT":
      localStorage.clear();
      return { ...state, authAdminData: null };
    case "GET_ADMINS": {
      let adminsCurrentPage = action?.payload?.currentPage;
      let adminsNumberOfPages = action?.payload?.numberOfPages;
      return {
        ...state,
        admins: action?.payload?.admins || [],
        adminsCurrentPage,
        adminsNumberOfPages,
      };
    }
    case "GET_NONAPPROVED_ADMINS": {
      let nonApprovedAdminsCurrentPage = action?.payload?.currentPage;
      let nonApprovedAdminsNumberOfPages = action?.payload?.numberOfPages;
      return {
        ...state,
        nonApprovedAdmins: action?.payload?.admins || [],
        nonApprovedAdminsCurrentPage,
        nonApprovedAdminsNumberOfPages,
      };
    }
    case "APPROVE_DISAPPROVE_ADMINS": {
      return { ...state };
    }
    case "CREATE_ADMIN": {
      return { ...state };
    }
    default:
      return state;
  }
};

export default authAdminReducer;
