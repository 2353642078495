import React, { useEffect, useState } from "react";
import {
  ButtonContainer,
  CancelButton,
  FileInput,
  InputText,
  InputTextArea,
  SubmitButton,
} from "../RoutingContainer/CustomRoute/styles";
import {
  SelectValue,
  Wrapper,
  Selector,
  SelectorWrapper,
  SelectorLabel,
  InputContainer,
  FileInput2,
  MinifiedWrapper,
} from "./styles";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {
  postRouteData,
  editRouteData,
  getRoutePosts,
  getRoutes,
} from "../../actions/routes";
import {
  setBlogPostCreatorData,
  setActiveRoutePostEditor,
  setWaitingUpload,
  setRoutingCreatorData,
} from "../../actions/global";
import LoadingBar from "../LoadingBar/LoadingBar";

function RoutePostsCreator({ route }, ...rest) {
  const dispatch = useDispatch();
  const routePostsFilter = useSelector(
    (state) => state.globalReducer.routePostsFilter
  );
  const subRoutePostsFilter = useSelector(
    (state) => state.globalReducer.subRoutePostsFilter
  );
  const history = useHistory();
  const location = useLocation();
  const {
    category = false,
    description = false,
    fileUpload = false,
    link = false,
    tags = false,
    title = false,
    _id = null,
    type = "classic",
  } = route;

  const defaultPostData = {
    categoryData: "",
    descriptionData: "",
    fileData: [], //Array(100).fill("", 0),
    linkData: "",
    tagsData: "",
    titleData: "",
    typeData: "",
    amountGallery: 0,
    fileDataVals: [],
    routeId: null,
    minifiedPost: false,
  };

  const routingCreatorData = useSelector(
    (state) => state.globalReducer.routingCreatorData
  );
  const waitingForUpload = useSelector(
    (state) => state.globalReducer.waitingForUpload
  );
  const [postData, setPostData] = useState(defaultPostData);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [buttonTitle, setButtonTitle] = useState("Lägg till");
  const [fileDisabled, setFileDisabled] = useState(false);
  const user = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    if (routingCreatorData) {
      setButtonTitle("Uppdatera");
    } else {
      setButtonTitle("Lägg till");
    }
  }, [routingCreatorData]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (routingCreatorData) {
      dispatch(
        editRouteData(
          location,
          _id,
          routingCreatorData.postId,
          {
            newPost: {
              file: postData.fileData,
              category: postData.categoryData,
              description: postData.descriptionData,
              link: postData.linkData,
              tags: postData.tagsData,
              title: postData.titleData,
              type: postData.typeData,
              amountGallery: postData.amountGallery,
              fileDataVals: postData.fileDataVals,
              minifiedPost: postData.minifiedPost,
            },
            user: user,
            query:
              routingCreatorData.origin == "routePosts"
                ? routePostsFilter
                : subRoutePostsFilter,
            origin: routingCreatorData.origin,
          },
          history
        )
      );
    } else {
      dispatch(postRouteData(location, _id, { postData, user }, history));
    }
    setPostData(defaultPostData);
    dispatch(setWaitingUpload(true));
    dispatch(setRoutingCreatorData(null));
  };

  const shouldShow =
    category || description || fileUpload || link || tags || title;

  const clear = (e) => {
    e.preventDefault();
    dispatch(setActiveRoutePostEditor(null));
    dispatch(setBlogPostCreatorData(null));
    setPostData(defaultPostData);
    setButtonTitle("Lägg till");
  };

  useEffect(() => {}, [postData]);

  useEffect(() => {
    isButtonDisabled();
    isFileDisabled();
  }, [postData]);

  useEffect(() => {
    if (routingCreatorData) {
      setPostData({
        titleData: routingCreatorData.title ? routingCreatorData.title : "",
        linkData: routingCreatorData.link ? routingCreatorData.link : "",
        descriptionData: routingCreatorData.description
          ? routingCreatorData.description
          : "",
        fileData: routingCreatorData.file ? routingCreatorData.file : [],
        tagsData: routingCreatorData.tags ? routingCreatorData.tags : "",
        categoryData: routingCreatorData.category
          ? routingCreatorData.category
          : "",
        typeData: routingCreatorData.type ? routingCreatorData.type : "classic",
        amountGallery: routingCreatorData.amountGallery
          ? routingCreatorData.amountGallery
          : routingCreatorData.file
          ? routingCreatorData.file.length
          : 0,
        fileDataVals: routingCreatorData.fileDataVals
          ? routingCreatorData.fileDataVals
          : [],
        minifiedPost: routingCreatorData.minifiedPost
          ? routingCreatorData.minifiedPost
          : false,
      });
    }
  }, [routingCreatorData]);

  const isButtonDisabled = () => {
    let disabled =
      (postData.titleData ||
        postData.descriptionData ||
        postData.linkData ||
        postData.categoryData ||
        postData.fileData ||
        postData.tagsData) == "";

    disabled = postData.typeData == "image" && postData.fileData == ""; // to make sure that image is selected when using imagecomponent

    setButtonDisabled(disabled);
  };

  const isFileDisabled = () => {
    let disabled = postData.typeData == "gallery";
    setFileDisabled(disabled);
  };

  const handleFileChange = async (event, index) => {
    const { target = {} } = event;
    const { files = [] } = target;
    let file = files[0]; //files[0]; //the input is keeping information of files in array

    if (file) {
      const fileBase64 = await getBase64File(file);
      const { name = null, size = null, type = null } = file;
      const newObject = {
        name,
        size,
        type,
        base64: fileBase64,
      };
      file = newObject;

      let postDataCopy = postData;
      if (file == null || file == "") {
        delete postDataCopy.fileData[index];
        delete postDataCopy.fileDataVals[index];
      } else {
        postDataCopy.fileData[index] = file;
        postDataCopy.fileDataVals[index] = file.name;
      }
      setPostData({ ...postData, fileData: postDataCopy.fileData });
    }
  };

  const getBase64File = (file) =>
    new Promise((res, rej) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      let result;

      reader.onload = function () {
        res(reader.result);
      };

      reader.onerror = function (err) {
        rej(err);
      };
    });

  return !shouldShow ? null : (
    <Wrapper>
      <form autoComplete="off" noValidate onSubmit={handleSubmit}>
        {title && !(postData.typeData == "image") && (
          <InputText
            placeholder={"Titel*"}
            type="input"
            value={postData.titleData}
            onChange={(e) =>
              setPostData({ ...postData, titleData: e.target.value })
            }
          />
        )}
        {category && !(postData.typeData == "gallery") && (
          <InputText
            placeholder={"Category*"}
            type="input"
            value={postData.categoryData}
            onChange={(e) =>
              setPostData({ ...postData, categoryData: e.target.value })
            }
          />
        )}
        {tags && !(postData.typeData == "gallery") && (
          <InputText
            placeholder={"Tags*"}
            type="input"
            value={postData.tagsData}
            onChange={(e) =>
              setPostData({ ...postData, tagsData: e.target.value })
            }
          />
        )}
        {link &&
          !(postData.typeData == "image" || postData.typeData == "gallery") && (
            <InputText
              placeholder={"Länk*"}
              type="input"
              value={postData.linkData}
              onChange={(e) =>
                setPostData({ ...postData, linkData: e.target.value })
              }
            />
          )}
        {description &&
          !(postData.typeData == "image" || postData.typeData == "gallery") && (
            <InputTextArea
              placeholder={"Beskrivning*"}
              type="textarea"
              value={postData.descriptionData}
              onChange={(e) =>
                setPostData({ ...postData, descriptionData: e.target.value })
              }
            />
          )}
        {!fileDisabled && (
          <InputContainer>
            <FileInput2
              type="file"
              accept=".png, .jpeg, .jpg"
              //value={postData.fileData}
              multiple={false}
              onChange={(file) => handleFileChange(file, 0)}
            />
            <SelectorLabel>{postData.fileDataVals[0]}</SelectorLabel>
          </InputContainer>
        )}

        {
          <SelectorWrapper>
            <SelectorLabel>Typ av post</SelectorLabel>
            <Selector
              name="Types"
              id="Types"
              onChange={(e) =>
                setPostData({ ...postData, typeData: e.target.value })
              }
              value={postData.typeData}
            >
              <SelectValue value="classic">Classic</SelectValue>
              <SelectValue value="image">Image</SelectValue>
              <SelectValue value="reversed classic">
                Reversed Classic
              </SelectValue>
              {/*(<SelectValue value="gallery">Gallery</SelectValue>)*/}
            </Selector>
          </SelectorWrapper>
        }
        <MinifiedWrapper>
          <SelectorLabel>Minifierad post</SelectorLabel>
          <InputText
            checkbox={true}
            placeholder={"Minifierad"}
            type="checkbox"
            checked={postData.minifiedPost}
            onChange={(e) =>
              setPostData({ ...postData, minifiedPost: !postData.minifiedPost })
            }
          />
        </MinifiedWrapper>

        {postData.typeData == "gallery" && (
          <InputText
            placeholder={"Antal bilder*"}
            type="input"
            value={postData.amountGallery}
            onChange={(e) =>
              setPostData({ ...postData, amountGallery: e.target.value })
            }
          />
        )}
        {postData.typeData == "gallery" &&
          Array(Number(postData.amountGallery))
            .fill("", 0)
            .map((value, index) => (
              <InputContainer>
                <FileInput2
                  type="file"
                  accept=".png, .jpeg, .jpg"
                  //value={postData.fileData}
                  multiple={false}
                  onChange={(file) => handleFileChange(file, index)}
                />
                <SelectorLabel>{postData.fileDataVals[index]}</SelectorLabel>
              </InputContainer>
            ))}
        <ButtonContainer waitingForUpload={waitingForUpload}>
          <SubmitButton
            disabled={buttonDisabled || waitingForUpload == true}
            type="submit"
          >
            {buttonTitle}
          </SubmitButton>
          <CancelButton onClick={clear}>Avbryt</CancelButton>
        </ButtonContainer>
        {waitingForUpload == true ? (
          <LoadingBar value="Uppladdning pågår" />
        ) : null}
      </form>
    </Wrapper>
  );
}

export default RoutePostsCreator;
