import * as api from "../api/index";
import { setWaitingUpload } from "./global";

export const fetchFile = (postId, history) => async (dispatch) => {
  try {
    await api
      .fetchFiles(postId)
      .then((res) => {
        if (res.status == 200) {
          dispatch({ type: "FETCH_FILE", payload: res.data });
        }
      })
      .catch((err) => {
        dispatch({ type: "AUTH_LOGOUT" });
        dispatch({
          type: "SET_ERRORMESSAGE",
          payload: err,
        });
        history.push("/error");
      });
  } catch (error) {
    console.error("ERROR WHEN FETCHING FILES: ", error);
  }
};

export const emptyRoutePosts = () => {
  return {
    type: "EMPTY_ROUTEPOSTS",
  };
};

export const emptySubRoutePosts = () => {
  return {
    type: "EMPTY_SUB_ROUTEPOSTS",
  };
};

export const deleteFile = (history) => async (dispatch) => {};

export const getRoutes = (history) => async (dispatch) => {
  try {
    await api
      .getRoutes()
      .then((res) => {
        if (res.status === 200) {
          dispatch({ type: "FETCH_ROUTES", payload: res.data });
        }
      })
      .catch((err) => {
        dispatch({ type: "AUTH_LOGOUT" });
        dispatch({
          type: "SET_ERRORMESSAGE",
          payload: err,
        });
        history.push("/error");
      });
  } catch (error) {
    console.error("ERROR IN GET ROUTES: ", error);
  }
};

export const editRoute = (id, newRouteData, history) => async (dispatch) => {
  try {
    await api
      .editRoute(id, newRouteData)
      .then((res) => {
        if (res.status === 200) {
          dispatch({ type: "UPDATE_ROUTE", payload: res.data });
          dispatch(getRoutes());
        }
      })
      .catch((err) => {
        dispatch({ type: "AUTH_LOGOUT" });
        dispatch({
          type: "SET_ERRORMESSAGE",
          payload: err,
        });
        history.push("/error");
      });
  } catch (error) {
    console.error("ERROR IN CHANGE ORDER: ", error);
  }
};

export const createRoute = (newRoute, history) => async (dispatch) => {
  try {
    await api
      .createRoute(newRoute)
      .then((res) => {
        if (res.status === 203) {
          dispatch({ type: "AUTH_LOGOUT" });
          history.push("/login-admin");
        } else if (res.status === 201) {
          dispatch({ type: "CREATE_ROUTE", payload: res.data });
        }
      })
      .catch((err) => {
        dispatch({ type: "AUTH_LOGOUT" });
        dispatch({
          type: "SET_ERRORMESSAGE",
          payload: err,
        });
        history.push("/error");
      });
  } catch (error) {
    console.error("ERROR IN CREATE ROUTE: ", error);
  }
};

export const deleteRoute = (id, history, user) => async (dispatch) => {
  try {
    await api
      .deleteRoute(id, user)
      .then((res) => {
        if (res.status === 200) {
          dispatch({ type: "DELETE_ROUTE", payload: id });
          history.push("/hem");
        } else if (res.status === 203) {
          dispatch({ type: "AUTH_LOGOUT" });
          history.push("/login-admin");
        }
      })
      .catch((err) => {
        dispatch({ type: "AUTH_LOGOUT" });
        dispatch({
          type: "SET_ERRORMESSAGE",
          payload: err,
        });
        history.push("/error");
      });
  } catch (error) {
    console.error("ERROR IN DELETE ROUTE: ", error);
  }
};

export const getRoutePosts = (id, history, page, query) => async (dispatch) => {
  try {
    await api
      .getRoutePosts(id, page, query)
      .then((res) => {
        if (res.status === 200) {
          dispatch({ type: "FETCH_ROUTE_POSTS", payload: res.data });
        } else {
          dispatch({
            type: "SET_ERRORMESSAGE",
            payload: "SOMETHING WENT WRONG",
          });
          history.push("/error");
        }
      })
      .catch((err) => {
        dispatch({ type: "AUTH_LOGOUT" });
        dispatch({
          type: "SET_ERRORMESSAGE",
          payload: err,
        });
        history.push("/error");
      });
  } catch (error) {
    console.error("ERROR IN GET ROUTE POSTS: ", error);
  }
};

export const postRouteData =
  (location, id, data, history) => async (dispatch) => {
    try {
      await api
        .postRouteData(id, data)
        .then((res) => {
          if (res.status === 201) {
            //dispatch({ type: "POST_ROUTE_DATA", payload: res.data });
            let locationArray = location.pathname.replace("/r/", "");
            locationArray = locationArray.split("/sr/");
            dispatch(
              fetchSubRouteData(
                locationArray[0],
                locationArray[1],
                null,
                history,
                0
              )
            );
            dispatch(getRoutePosts(id, history, 0));
            dispatch(setWaitingUpload(false));
          } else if (res.status === 203) {
            dispatch({ type: "AUTH_LOGOUT" });
            history.push("/login-admin");
          }
        })
        .catch((err) => {
          dispatch({ type: "AUTH_LOGOUT" });
          dispatch({
            type: "SET_ERRORMESSAGE",
            payload: err,
          });
          history.push("/error");
        });
    } catch (error) {
      console.error("ERROR IN POST ROUTE DATA: ", error);
    }
  };

export const deleteRouteData =
  (location, routeId, dataId, history, user, query) => async (dispatch) => {
    try {
      await api
        .deleteRouteData(routeId, dataId, user)
        .then((res) => {
          if (res.status === 200) {
            //dispatch({ type: "DELETE_ROUTE_DATA", payload: dataId });
            let locationArray = location.pathname.replace("/r/", "");
            locationArray = locationArray.split("/sr/");
            dispatch(
              fetchSubRouteData(
                locationArray[0],
                locationArray[1],
                null,
                history,
                0,
                query
              )
            );
            dispatch(getRoutePosts(routeId, history, 0, query));
            dispatch(setWaitingUpload(false));
          } else if (res.status === 203) {
            dispatch({ type: "AUTH_LOGOUT" });
            history.push("/login-admin");
          }
        })
        .catch((err) => {
          dispatch({ type: "AUTH_LOGOUT" });
          dispatch({
            type: "SET_ERRORMESSAGE",
            payload: err,
          });
          history.push("/error");
        });
    } catch (error) {
      console.error("ERROR IN POST ROUTE DATA: ", error);
    }
  };

export const editRouteData =
  (location, routeId, dataId, newData, history) => async (dispatch) => {
    try {
      await api
        .editRouteData(routeId, dataId, newData)
        .then((res) => {
          if (res.status === 200) {
            //dispatch({ type: "EDIT_ROUTE_DATA", payload: res.data });
            let locationArray = location.pathname.replace("/r/", "");
            locationArray = locationArray.split("/sr/");
            dispatch(
              fetchSubRouteData(
                locationArray[0],
                locationArray[1],
                null,
                history,
                0,
                newData.query
              )
            );
            dispatch(getRoutePosts(routeId, history, 0, newData.query));
            dispatch(setWaitingUpload(false));
          } else if (res.status === 203) {
            dispatch({ type: "AUTH_LOGOUT" });
            history.push("/login-admin");
          }
        })
        .catch((err) => {
          dispatch({ type: "AUTH_LOGOUT" });
          dispatch({
            type: "SET_ERRORMESSAGE",
            payload: err,
          });
          history.push("/error");
        });
    } catch (error) {
      console.error("ERROR IN EDIT ROUTE DATA: ", error);
    }
  };

export const createSubRoute = (id, data, history) => async (dispatch) => {
  try {
    await api
      .createSubRoute(id, data)
      .then((res) => {
        if (res.status === 201) {
          dispatch({ type: "CREATE_SUBROUTE", payload: res.data });
        } else if (res.status === 203) {
          dispatch({ type: "AUTH_LOGOUT" });
          history.push("/login-admin");
        }
      })
      .catch((err) => {
        dispatch({ type: "AUTH_LOGOUT" });
        dispatch({
          type: "SET_ERRORMESSAGE",
          payload: err,
        });
        history.push("/error");
      });
  } catch (error) {
    console.error("ERROR IN EDIT ROUTE DATA: ", error);
  }
};

export const fetchSubRouteData =
  (parentId, childId, id, history, page, query) => async (dispatch) => {
    try {
      await api
        .fetchSubRouteData(parentId, childId, id, page, query)
        .then((res) => {
          if (res.status === 200) {
            dispatch({ type: "FETCH_SUBROUTES_DATA", payload: res.data });
          }
        })
        .catch((err) => {
          dispatch({ type: "AUTH_LOGOUT" });
          dispatch({
            type: "SET_ERRORMESSAGE",
            payload: err,
          });
          history.push("/error");
        });
    } catch (error) {
      console.error("ERROR WHEN FETCHING SUB ROUTE DATA: ", error);
    }
  };

export const setEditingFile = (file, history) => async (dispatch) => {
  try {
    dispatch({ type: "EDITING_FILE", payload: file });
  } catch (err) {
    dispatch({ type: "AUTH_LOGOUT" });
    dispatch({
      type: "SET_ERRORMESSAGE",
      payload: err,
    });
    history.push("/error");
  }
};

export const getRoutePost = (id, history) => async (dispatch) => {
  try {
    await api
      .getRoutePost(id)
      .then((res) => {
        if (res.status === 200) {
          dispatch({ type: "GET_ROUTEPOST", payload: res.data });
        }
      })
      .catch((err) => {
        dispatch({ type: "AUTH_LOGOUT" });
        dispatch({
          type: "SET_ERRORMESSAGE",
          payload: err,
        });
        history.push("/error");
      });
  } catch (error) {
    console.error("ERROR WHEN FETCHING SUB ROUTE DATA: ", error);
  }
};

export const setDetailedPost = (post, history) => async (dispatch) => {
  try {
    dispatch({ type: "SET_DETAILED_POST", payload: post });
  } catch (err) {
    console.error("ERROR IN SET DETAILED POST: ", err);
  }
};
