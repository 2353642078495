import React from "react";
import { Container, Bar, TextContainer, Text } from "./styles";

function LoadingBar({ value = "Laddar" }) {
  return (
    <Container>
      <Bar />
      <TextContainer>
        <Text>{value}</Text>
      </TextContainer>
    </Container>
  );
}

export default LoadingBar;
