import styled from "styled-components";
import { Link } from "react-router-dom";
import FileBase64 from "react-file-base64";
import { TrashIcon, CaretSquareUp, CaretSquareDown } from "../../../Icons/Icon";

export const LinkItem = styled(Link)`
  padding-left: 55px;
  align-items: start;
  text-decoration: none;
  font-size: var(--thirdHeadingSize);
  color: var(--mainTextColor);
  width: 95%;
  border-radius: 6px;
  justify-content: space-between;

  &:hover {
    color: var(--mainTextColor);
    background-color: var(--mainHoverColor);
  }
`;

export const IconContainer2 = styled.div`
  position: relative;
  padding-right: 10px;
  margin-left: 15px;
  z-index: 99;
  float: right;
  text-align: center;
`;

export const ArrowUp = styled(CaretSquareUp)`
  width: var(--mainFontSize);
  height: var(--mainFontSize);

  &:hover {
    fill: var(--mainIconHoverColor);
    cursor: pointer;
  }

  &:disabled {
    cursor: not-allowed;
    fill: var(--mainDisabledColor);
  }
`;

export const ArrowDown = styled(CaretSquareDown)`
  width: var(--mainFontSize);
  height: var(--mainFontSize);

  &:hover {
    fill: var(--mainIconHoverColor);
    cursor: pointer;
  }

  &:disabled {
    cursor: not-allowed;
    fill: var(--mainDisabledColor);
  }
`;

export const Icon = styled.i`
  margin-right: 10px;
  font-size: var(--mainFontSize);
  color: var(--mainTextColor);
  transition: 0.35s;
`;

export const Wrapper = styled.div`
  display: flex;
  margin: 5px 0px;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const DeleteIcon = styled(TrashIcon)`
  height: var(--mainFontSize);
  &:hover {
    fill: var(--mainIconHoverColor);
    cursor: pointer;
  }
`;

export const DeleteContainer = styled.div`
  position: relative;
  padding-right: 10px;
  float: right;
`;

export const IconContainer = styled.div`
  padding: 2px 2px 2px 0px;
  margin-right: 5px;
  position: relative;
  float: left;
  height: var(--mediumIconSize);
  width: var(--mediumIconSize);
`;

export const InputText = styled.input`
  width: 100%;
  margin: 5px 0px;
  border-radius: 2px;
  border: none;
  font-size: var(--thirdFontSize);
  padding: 2px;

  &:active {
    border: none;
    border-color: none;
  }

  &:focus {
    border-color: var(--mainHoverColor);
  }
`;

export const FileInput = styled(FileBase64)`
  width: 95%;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

export const SubmitButton = styled.button`
  margin-top: 15px;
  background-color: var(--secondaryColor);
  border-radius: 2px;
  color: var(--mainTextColor);
  border: none;
  width: 40%;
  font-size: var(--thirdFontSize);

  &:active {
    border: none;
  }

  &:hover {
    background-color: var(--mainHoverColor);
    cursor: pointer;
  }

  &:disabled {
    cursor: default;
    bottom: 0;
    opacity: 0.65;

    &:hover {
      background-color: var(--secondaryColor);
    }
  }
`;

export const CancelButton = styled.button`
  margin-top: 15px;
  background-color: var(--secondaryColor);
  border-radius: 2px;
  color: var(--mainTextColor);
  border: none;
  width: 40%;
  font-size: var(--thirdFontSize);

  &:active {
    border: none;
  }

  &:hover {
    background-color: var(--mainHoverDelete);
    cursor: pointer;
  }

  &:disabled {
    cursor: default;
    bottom: 0;
    opacity: 0.65;

    &:hover {
      background-color: var(--secondaryColor);
    }
  }
`;

export const SubRouteIcon = styled.div`
  height: 16px;
  width: 16px;
  background-image: url(/svgs/subCategory.svg);
`;
