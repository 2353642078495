import React from "react";
import { NavBar, Wrapper, OuterWrapper, CloseLeftNav } from "./styles";
import { useDispatch } from "react-redux";
import LeftNavItem from "./LeftNavItem/LeftNavItem";
import RoutingContainer from "../RoutingContainer/RoutingContainer";
import { useSelector } from "react-redux";
import styled from "styled-components";
import SettingsContainer from "./SettingsContainer/SettingsContainer";
import { ToggleLeftNav } from "../../actions/navbar";

function LeftNavBar() {
  const dispatch = useDispatch();
  const activeNav = useSelector((state) => state.navbarReducer.showLeftNav);
  const style = () => {
    return {
      left: activeNav ? "0" : "-300px",
      boxShadow: activeNav ? "4px 0px 5px rgba(0, 0, 0, 0.6)" : "none",
    };
  };

  const closeLeftNav = () => {
    dispatch(ToggleLeftNav());
  };

  return (
    <OuterWrapper activeNav={activeNav}>
      <NavBar style={style()}>
        <Wrapper>
          <LeftNavItem link="/hem" value="Hem" />
          <LeftNavItem link="/om-oss" value="Om oss" />
          <LeftNavItem link="/om-rasen" value="Om rasen" />
          <RoutingContainer />
          <LeftNavItem link="/kontakt" value="Kontakt" />
        </Wrapper>
        <SettingsContainer />
      </NavBar>
      <CloseLeftNav onClick={closeLeftNav} activeNav={activeNav} />
    </OuterWrapper>
  );
}

export default LeftNavBar;
