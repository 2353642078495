import React, { useState } from "react";
import { Header, IconWrapper, Wrapper } from "./styles";
import { AllIcons } from "../../Icons/Icon";

function IconContainer({ setSelectedIcon }) {
  const [selected, setSelected] = useState();

  const select = (index) => {
    setSelectedIcon(index);
    setSelected(index);
  };
  return (
    <Wrapper>
      {AllIcons.map((e, index) => (
        <IconWrapper
          style={
            selected == index
              ? { borderColor: "var(--mainIconHoverColor)" }
              : {}
          }
          key={"icon:" + index}
          onClick={() => select(index)}
        >
          {e}
        </IconWrapper>
      ))}
    </Wrapper>
  );
}

export default IconContainer;
