import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  border-radius: 6px;
  background-color: var(--thirdColor);
  width: 95%;
  align-self: center;
  overflow: hidden;
  transition: 0.35s;
  margin: 5px 0px;
`;

export const Checkbox = styled.input`
  margin-right: 5px;
`;

export const CheckboxLabel = styled.span`
  font-size: var(--thirdFontSize);
`;

export const InputText = styled.input`
  width: 100%;
  margin: 5px 0px;
  border-radius: 2px;
  border: none;
  font-size: var(--thirdFontSize);
  padding: 2px;

  &:active {
    border: none;
    border-color: none;
  }

  &:focus {
    border-color: var(--mainHoverColor);
  }
`;

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 5px;
`;

export const Heading = styled.h1`
  font-size: var(--mainFontSize);
`;

export const SubmitButton = styled.button`
  margin-top: 15px;
  background-color: var(--secondaryColor);
  border-radius: 2px;
  color: var(--mainTextColor);
  border: none;
  width: 40%;
  font-size: var(--thirdFontSize);

  &:active {
    border: none;
  }

  &:hover {
    background-color: var(--mainHoverColor);
    cursor: pointer;
  }

  &:disabled {
    cursor: default;
    bottom: 0;
    opacity: 0.65;

    &:hover {
      background-color: var(--secondaryColor);
    }
  }
`;

export const CancelButton = styled.button`
  margin-top: 15px;
  background-color: var(--secondaryColor);
  border-radius: 2px;
  color: var(--mainTextColor);
  border: none;
  width: 40%;
  font-size: var(--thirdFontSize);

  &:active {
    border: none;
  }

  &:hover {
    background-color: var(--mainHoverDelete);
    cursor: pointer;
  }

  &:disabled {
    cursor: default;
    bottom: 0;
    opacity: 0.65;

    &:hover {
      background-color: var(--secondaryColor);
    }
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

export const IconHeader = styled.span`
  font-size: var(--thirdFontSize);
  margin-top: 25px;
`;
