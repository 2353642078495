/**
 * Used in the left navbar to display all the available custom routes.
 */

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import RoutingCreator from "../RoutingCreator/RoutingCreator";
import { Wrapper, AddIcon } from "./styles";
import { getRoutes } from "../../actions/routes";
import CustomRoute from "./CustomRoute/CustomRoute";
import ContainerDivider from "../ContainerDivider/ContainerDivider";
import LoadingBar from "../LoadingBar/LoadingBar";

function RoutingContainer({ activetype, editableChildren = false }) {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const [routesLength, setRoutesLength] = useState(0);
  const [activeSubRoute, setActiveSubRoute] = useState(""); //Added for show/hide when not parentCategry or child is not selected
  const routes = useSelector((state) => state.routingReducer.routes);
  const addNewRoute = () => {
    //Dispatch / set another active blogpostcreator so you wont see the others in the menu.
    setActiveNow(!activeNow);
  };

  useEffect(() => {
    dispatch(getRoutes(history));
  }, []);

  useEffect(() => {
    if (routes && routes.length)
      setRoutesLength(
        routes.filter((route) => route.isSubRoute != true).length
      );
  }, [routes]);

  const [activeNow, setActiveNow] = useState(false);

  const hideRoutingContainer = () => {
    setActiveNow((state) => !state);
  };

  const [loggedInUser, setLoggedInUser] = useState(null);

  useEffect(() => {
    setLoggedInUser(JSON.parse(localStorage.getItem("user")));
  }, [location]);

  return (
    <Wrapper>
      {routes ? (
        routes
          .filter((route) => !route.isSubRoute)
          .map((route) => (
            <CustomRoute
              activeSubRoute={activeSubRoute}
              setActiveSubRoute={setActiveSubRoute}
              link={`/r/${route._id}`}
              name={route.name}
              id={route._id}
              route={route}
              routesLength={routesLength}
            />
          ))
      ) : (
        <LoadingBar value="Hämtar hem kategorier" />
      )}

      {loggedInUser && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            alignItems: "center",
          }}
        >
          <ContainerDivider />
          {activeNow && (
            <RoutingCreator
              hideRoutingContainer={hideRoutingContainer}
              type={"customRouting"}
            />
          )}
          <AddIcon onClick={addNewRoute} className="fas fa-plus" />
        </div>
      )}
    </Wrapper>
  );
}

export default RoutingContainer;
